import { Autoplay, Swiper } from 'swiper';

export default function setPartnersSlider(query) {
  new Swiper(query, {
    modules: [Autoplay],
    loop: true,
    slidesPerView: 'auto',
    spaceBetween: 45,
    speed: 6000,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
      pauseOnMouseEnter: false
    },
    breakpoints: {
      768: {
        spaceBetween: 60
      },
      992: {
        spaceBetween: 80
      }
    }
  });
}
