import { Navigation, Swiper } from 'swiper';

export default function setInsightsSlider(query) {
    const sliders = document.querySelectorAll(query);
    if (!sliders || !sliders.length) return;

    [...sliders].forEach((slider) => {
      const anchor = slider.dataset.anchor || '';

      new Swiper(`${query}[data-anchor=${anchor}]`, {
        modules: [ Navigation ],
        slidesPerView: 1,
        spaceBetween: 20,
        speed: 1000,
        navigation: {
          nextEl: `.block-insights--navigation[data-anchor=${anchor}] .block-insights--next`,
          prevEl: `.block-insights--navigation[data-anchor=${anchor}] .block-insights--prev`
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
            spaceBetween: 35
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 45
          }
        }
      });
    });
}
