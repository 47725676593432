export default function setFiles(query) {
  const fields = document.querySelectorAll(query);
  if (!fields || !fields.length) return;

  [...fields].forEach((field) => {
    const input = field.querySelector('input[type="file"]');
    if (!input) return;

    const trigger = field.querySelector(`${query}-trigger`);
    if (!trigger) return;

    const area = field.querySelector(`${query}-filename`);
    if (!area) return;

    input.addEventListener('change', () => {
      const { files } = input;

      if (!input.files.length) {
        trigger.classList.remove('fullfilled');
      } else {
        trigger.classList.add('fullfilled');
        area.textContent = files[0].name || 'Fichier sans nom';
      }
    });
  });
}
