export default function setHeader(query) {
  const header = document.querySelector(query);
  if (!header) return;

  window.addEventListener('scroll', () => {
    if (window.scrollY > 25) {
      header.classList.add('scroll');
    } else {
      header.classList.remove('scroll');
    }
  });
}
