export default function moveLangSwitcher (query) {
    const switcher = document.querySelector(query);
    if (!switcher) return;

    const parent = switcher.parentNode;
    const button = parent.querySelector('.menu-item-button');

    button.before(switcher);

    setTimeout(() => switcher.classList.remove('hidden'));
}
