export default function setMobileMenu(query) {
  const menu = document.querySelector(query.menu);
  if (!menu) return;

  const toggle = document.querySelector(query.toggle);
  if (!toggle) return;

  toggle.addEventListener('click', () => {
    const isCollapsed = menu.classList.contains('collapsed');

    if (!isCollapsed) {
      document.body.style.overflowY = 'hidden';

      menu.classList.add('collapsed');
      toggle.classList.add('times');
    } else {
      document.body.style.overflowY = '';

      menu.classList.remove('collapsed');
      toggle.classList.remove('times');
    }
  });

  const links = menu.querySelectorAll('a');
  if (!links || !links.length) return;

  [...links].forEach((link) => {
    link.addEventListener('click', () => {
      document.body.style.overflowY = '';

      menu.classList.remove('collapsed');
      toggle.classList.remove('times');
    });
  });

  window.addEventListener('resize', () => {
    document.body.style.overflowY = '';

    menu.classList.remove('collapsed');
    toggle.classList.remove('times');
  });
}
