export default function setDropdowns(query) {
  const navigation = document.querySelector(query);
  if (!navigation) return;

  const empty = navigation.querySelectorAll('li.menu-item-dropdown');
  if (!empty || !empty.length) return;

  [...empty].forEach((li) => {
    const link = li.querySelector('a');
    if (!link) return;

    link.addEventListener('click', (event) => event.preventDefault());
  });
}
