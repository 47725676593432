import { Autoplay, Pagination, EffectFade, Swiper } from 'swiper';

export default function setHeroSlider(query) {
  window.addEventListener('load', () => {
    setTimeout(() => {
      new Swiper(query, {
        modules: [EffectFade, Pagination, Autoplay],
        loop: true,
        slidesPerView: 1,
        effect: 'fade',
        speed: 3000,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
          pauseOnMouseEnter: false
        },
        pagination: {
          el: `${query}-pagination`,
          clickable: true
        }
      });
    }, 800);
  });
}
