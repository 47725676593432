import setHeader from './modules/header';
import setMobileMenu from './modules/mobile-menu';
import setDropdowns from './modules/dropdowns';
import setPreloader from './modules/preloader';
import setPolicy from './modules/policy';
import setHeroSlider from './modules/hero-slider';
import setPartnersSlider from './modules/partners';
import setFiles from './modules/files';
import setInsightsSlider from './modules/insights-slider';

import moveLangSwitcher from './modules/lang-switcher';

import '../scss/styles.scss';

document.addEventListener('DOMContentLoaded', () => {
  window.scrollTo({ left: 0, top: 0 });

  setHeader('.site-header');
  setMobileMenu({ menu: '.site-header--navigation', toggle: '.site-menu-toggle' });
  setDropdowns('.site-header--navigation');
  setPreloader('.site-preloader');
  setPolicy('#cmplz-document');
  setHeroSlider('.block-hero-slider--slider');
  setPartnersSlider('.block-partners--slider');
  setFiles('.block-form--file');
  setInsightsSlider('.block-insights--slider');

  moveLangSwitcher('.site-header .site-language-switcher');
});
